<template>
  <div>
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="5"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.photo_url"
              :text="userData.name != null ? userData.name.charAt(0) : '-'"
              :variant="`light-danger`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.name != null ? userData.name : '-' }}
                </h4>
                <span class="card-text text-break">{{ userData.email != null ? userData.email : '-' }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-dropdown
                  variant="info"
                  size="sm"
                >
                  <template #button-content>
                    Action
                  </template>
                  <b-dropdown-item
                    v-if="checkPermission('delete user')"
                    @click="deleteItem(userData.uuid)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-75"
                    />
                    Delete User
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="checkPermission('banned user')"
                    @click="userData.status == 'banned' ? bannedItem('unbanned', userData.uuid) : bannedItem('banned', userData.uuid)"
                  >
                    <feather-icon
                      :icon="userData.status == 'banned' ? 'UnlockIcon' : 'LockIcon'"
                      class="mr-75"
                    />
                    {{ userData.status == 'banned' ? 'Unbanned' : 'Banned' }} User
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <!-- Wallet -->
          <BalanceWalletUser :wallets="userData.wallets" />
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="7"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th
                class="pb-50"
                width="30%"
              >
                <feather-icon
                  icon="CreditCardIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">ID</span>
              </th>
              <td class="pb-50">
                {{ userData.uuid }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Phone</span>
              </th>
              <td>
                {{ userData.phone_number != null ? userData.phone_number : "-" }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Email</span>
              </th>
              <td>
                {{ userData.email != null ? userData.email : "-" }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Birthdate</span>
              </th>
              <td>
                <span v-if="userData.birthdate != null">
                  {{ userData.birthdate | formatBirthDate }}
                </span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Registered At</span>
              </th>
              <td>
                <span v-if="userData.registered_at != null">
                  {{ userData.registered_at | formatDateRegister }}
                </span>
                <span v-else>
                  -
                </span>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">User Registered</span>
              </th>
              <td>
                {{ userData.referral ? userData.referral.user_registered : 0 }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="UserPlusIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Referred By</span>
              </th>
              <td>
                <!-- <pre>{{ userData.referedBy.uuid }}</pre> -->
                <a @click="goToDetail()" v-if="userData.referedBy">
                  {{ userData.referedBy.name }}
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-75"
                  />
                </a>
                <span v-else>-</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookmarkIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50">
                <span
                  class="badge text-capitalize badge-pill"
                  :class="
                    userData.status == 'active'
                      ? 'badge-light-success'
                      : 'badge-light-secondary'
                  "
                >{{ userData.status }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="CodeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Refferal Code</span>
              </th>
              <td>
                {{ userData.referral_code != null ? userData.referral_code : "-" }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import {
  checkPermission,
  successNotification,
  errorNotification,
} from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import BalanceWalletUser from '@/components/User/BalanceWalletUser.vue'

Vue.use(VuejsDialog)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BalanceWalletUser,
  },
  props: ['userData'],
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  methods: {
    bannedItem(type, id) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure to ${type} this user?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`admin/users/${id}/${type}`)
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `User successfully ${type}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'users.index' })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http
            .delete(`admin/users/${id}`)
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'User successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'users.index' })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  'Oops!',
                  error.response.data.meta.messages,
                )
              }
            })
        }
      })
    },
    goToDetail() {
      this.$router.push(`/user-detail/${this.userData.referedBy.uuid}`)
    },
  },
}
</script>
