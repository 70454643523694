<template>
  <b-card class="table-responsive" no-body>
    <table
      class="table b-table w-100"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Postal Code</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in addresses"
          :key="item.uuid"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.postal_code }}</td>
        </tr>
        <tr v-if="addresses.length == 0">
          <td
            colspan="4"
            class="text-center"
          >
            <small>Data empty</small>
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BCardTitle,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
  },
  props: {
    id: String,
  },
  data() {
    return {
      addresses: Object,
    }
  },
  created() {
    this.getUserAddress()
  },
  methods: {
    getUserAddress() {
      this.$http.get(`/admin/users/${this.id}/addresses`).then(response => {
        this.addresses = response.data.data
      })
    },
  },
}
</script>
