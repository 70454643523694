<template>
  <div class="table-responsive">
    <table
      class="table b-table"
      style="width: 100%"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Registration Time</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td
            colspan="6"
            class="text-center"
          >
            <b-spinner
              class="mb-1"
              variant="primary"
            /><br>
            Loading...
          </td>
        </tr>
        <tr
          v-for="item in result.data"
          v-else
          :key="item.uuid"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.registered_at | formatDateRegister }}</td>
          <td>
            <b-dropdown
              variant="info"
              size="sm"
            >
              <template #button-content>
                Action
              </template>
              <b-dropdown-item
                v-if="checkPermission('show users')"
                @click="goToDetail(item.uuid)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-75"
                />
                Detail User
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
        <tr v-if="result.total == 0">
          <td
            colspan="4"
            class="text-center"
          >
            <small>Data empty</small>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="result.total > 0"
      class="m-1"
    >
      <div class="row">
        <div class="col mb-1">
          <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
        </div>
        <div class="col">
          <pagination
            :data="result"
            :limit="4"
            align="right"
            @pagination-change-page="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: ['id'],
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      filter: {
        user_type: '',
        referred_by: '',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page
      queryParams.user_type = 'normal'
      queryParams.referred_by = this.id

      this.$http.get('/admin/users', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    goToDetail(id) {
      this.$router.push({ name: 'users.show', params: { id } })
    },
  },
}
</script>
