<template>
  <b-row>
    <!-- Detail User -->
    <b-col
      cols="12"
      lg="12"
    >
      <DetailUserComponent :user-data="userData" />
    </b-col>

    <b-col
      cols="12"
      lg="12"
    >
      <b-card>
        <b-tabs content-class="pt-1">
          <b-tab
            active
            title="List of Successfully Invited Users"
          >
            <ListReferred :id="id" />
          </b-tab>
          <b-tab title="Address List">
            <AddressUser :id="id" />
          </b-tab>
          <b-tab
            title="Pital Pay Histories"
            @click="typeSlug = 'pay'"
          >
            <TransactionHistories
              :result="result"
              :type-slug="'pay'"
              :search-filter="false"
              :user-uuid="id"
              :is-loading="isLoading"
              :getData="getData"
              :type-user-or-talents="'users'"
            />
          </b-tab>
          <b-tab
            title="Pital Coin Histories"
            @click="typeSlug = 'coin'"
          >
            <TransactionHistories
              :result="result"
              :type-slug="'coin'"
              :search-filter="false"
              :user-uuid="id"
              :is-loading="isLoading"
              :getData="getData"
              :type-user-or-talents="'users'"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import AddressUser from '@/components/User/AddressUser/index.vue'
import DetailUserComponent from '@/components/User/DetailUser/index.vue'
import ListReferred from '@/components/User/DetailUser/ListReferred.vue'
import TransactionHistories from '@/components/Wallet/TransactionHistories.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Detail User`
  },
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    DetailUserComponent,
    ListReferred,
    AddressUser,
    TransactionHistories,
  },
  data() {
    return {
      id: this.$route.params.id,
      userData: Object,
      result: {},
      isLoading: false,
      typeSlug: 'pay',
      filter: {
        user_uuid: '',
        page: 1,
      },
    }
  },
  watch: {
    '$route.params.id': function (value) {
      this.id = value
      this.loadData()
    },
    typeSlug() {
      this.getData()
    },
  },
  created() {
    this.loadData()
    this.getData()
  },
  methods: {
    loadData() {
      this.$http.get(`/admin/users/${this.id}`).then(response => {
        this.userData = response.data.data
      })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      queryParams.user_uuid = this.id

      this.$http.get(`/admin/wallet/${this.typeSlug}`, {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
</style>
